import Vue from "vue"
import Vuetify from "vuetify"
import "vuetify/dist/vuetify.min.css"
import * as validation from "../validation"

Vue.use(Vuetify);

var a = new Vue({
    el: '#app',
    data() {
        return {
            modelstate: {},
            Credentials: {
                CredentialsInfo: {
                    email: "",
                    password: "",
                },
                rememberMe: false,
            },
            valid: false,
            show: false,
            rules: validation.rules
        };
    },
    methods: {}
});