export const
    errorMessages = {
        required: "Field is required!",
        email: "Ooops. Check that email format.",
        emailmax: "Email is too long",
        emailDuplicated: "Email is duplicated"
    };

export const

    rules = {
        required: value => !!value || errorMessages.required,
        requiredNumber: value => value != undefined || "Field is required",
        password: v => !!v.match(/^([a-zA-Z0-9]+)$/) || "Password should include only digits and latin letters",
        passwordmin: value => value.length >= 6 || "Password should include min 6 max 30 characters",
        passwordmax: value => value.length <= 30 || "Password should include min 6 max 30 characters",
        emailmax: value => value.length <= 45 || errorMessages.emailmax,
        email: v => !!v.match(/^([a-zA-Z0-9\.\,]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/)
            || errorMessages.email,
        category: value => (value != "Base" && value != "Registration" && value != "System") || "Incorrect category.",
    };
